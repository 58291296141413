<template>
  <div class="prebilled-amount">
    <v-alert dense outlined type="info">
      {{ $t("prebilled." + mode + ".text") }}
      <strong>
        {{ $n(prebilledAmount, "currency") }}
      </strong>
      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <strong v-bind="attrs" v-on="on">
            {{ $n(prebilledAmount, "currency") }}
          </strong>
        </template>
        <span>{{ $t("prebilled." + mode + ".tooltip") }}</span>
      </v-tooltip> -->
    </v-alert>
  </div>
</template>
<style scoped></style>
<script>
import CartService from "~/service/cartService";

var vm;

export default {
  name: "PrebilledAmount",
  props: {
    mode: { type: String, required: true },
    paymentTypeId: {
      type: Number,
      required: false
    },
    orderId: { type: Number, required: false },
    paymentDueDateIds: { type: Array, required: false }
  },
  data: function() {
    return {
      prebilledAmount: null
    };
  },
  methods: {
    async getPrebilledAmount() {
      if (vm.mode == "cart") {
        this.prebilledAmount = await CartService.getPreBilledAmount(
          vm.paymentTypeId
        );
      } else if (vm.mode == "order") {
        vm.prebilledAmount = await CartService.getPreBilledAmount(
          vm.paymentTypeId,
          vm.orderId
        );
      } else if (vm.mode == "duedate") {
        //TODO implement prebilled duedate
      }
    }
  },
  created() {
    vm = this;
    vm.getPrebilledAmount();
  }
};
</script>
